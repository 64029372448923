import React, { useMemo, useContext, useState } from "react";
import Records from "../Admin/Records";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Navigate } from "react-router-dom";
import { OpenDialog } from "../App";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import Loading from "../Loading";
import Logout from "../Logout";
import useFetch from "../../fetch/useFetch";

import useInvites from "../Admin/useInvites";

import { useRenderFunctions, Context } from "../App";
import ajax from "../../fetch/ajax";

const resetEmailText =
  "Troverai un link per la modifica della password nella tua email";

const useStyles = makeStyles((theme) => ({
  root: { margin: "20px 0 0 10px", width: "calc(100% - 20px)", padding: "2px" },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "0px",
    alignItems: "center",
    "& .quest": { margin: "0 20px", fontSize: "1rem" },
  },
  profButtons: {
    display: "inline-block",
    "& button": {
      margin: "0px 3px 6px 6px",
      color: "white",
    },
  },
}));

export default function Teacher() {
  const ctx = useContext(Context);
  const questionaries = useMemo(() => ctx.questionaries.data, [ctx]);
  const [actionFns, Overlays] = useInvites(questionaries);

  const [customDialog, setCustomDialog] = useState(null);
  const [profClassData, setProfClassData] = useState([]);

  const classes = useStyles();
  const [loading, , { role, token }] = useFetch({
    url: "api/checkLoginStatus.php",
    start: 1,
  });

  useRenderFunctions(
    useMemo(
      () =>
        token
          ? [
              () => (
                <>
                  <div>Area Docenti</div>
                  <div>Le tue classi: {profClassData.join(", ")}</div>
                </>
              ),
              () => (
                <>
                  <Logout role="teacher">Esci</Logout>{" "}
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<VpnKeyIcon />}
                    onClick={() =>
                      window.confirm(
                        "Vuoi davvero cambiare la tua password?"
                      ) &&
                      ajax("api_teacher/REST_profSelfReset.php")
                        .then(() => {
                          setCustomDialog(["Successo", resetEmailText]);
                        })
                        .catch(() => {
                          setCustomDialog([
                            "Errore",
                            "Invio email di reset fallito",
                          ]);
                        })
                    }
                  >
                    Cambia password
                  </Button>
                </>
              ),
            ]
          : [null, null],
      [token, profClassData]
    )
  );

  const showQuestionaries = useMemo(
    () =>
      actionFns.map(
        (q) =>
          window.__CONFIG__.questionari.includes(q.name) && (
            <div key={q.name}>
              <span className="quest">{q.name}</span>
              <Button
                variant="contained"
                color="primary"
                startIcon={<LibraryAddCheckIcon />}
                onClick={q.copy}
              >
                Copia
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<MailOutlineIcon />}
                onClick={q.send}
                style={{ marginLeft: "5px" }}
              >
                Invia
              </Button>
            </div>
          )
      ),
    [actionFns]
  );

  if (loading) return <Loading />;
  if (role.indexOf("prof") !== 0) return <Navigate to="/" replace />;
  return (
    <>
      {customDialog && (
        <OpenDialog
          title={customDialog[0]}
          text={customDialog[1]}
          clear={() =>
            customDialog[1] === resetEmailText
              ? document.getElementById("logOutButton").click()
              : setCustomDialog(null)
          }
        />
      )}
      {Overlays}
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Typography variant="body">LINK QUESTIONARI: </Typography>
          <div className={classes.actions}>{showQuestionaries}</div>
        </Toolbar>
      </AppBar>
      <Records isProf setProfClassData={setProfClassData} />
    </>
  );
}
